import gql from 'graphql-tag';


const UPDATE_ACHIEVEMENT = gql`
mutation updateAchievement($id:ID!, $input:AchievementInput! ) {
    updateAchievement( id:$id, input: $input ) {
      ok
      errors {
        field
        code
        message
      }
      resource {
    id
    date
    name
    achievementReason
    achievementType
    initiator
    requestLink
    pressReleaseLink
    pressFeedbackLink
    stateLevelCooperation
    topic {
        id
        name
        identifier
        description
      }
      topicText
      address
      
      district {
        id
        name
      }
      districtText
      statusText
      status {
        id
        name
      }

    active
    lat
    lon
    locations
    __typename
  }}}
`

  export default UPDATE_ACHIEVEMENT;
import React from 'react';

const NavigationButton = ({text, handleClick}) => {

    return (
    <div className="navigation_button" onClick={handleClick}>
        {text}
    </div>
    )
}

export default NavigationButton;
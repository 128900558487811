import * as React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { createUploadLink } from 'apollo-upload-client';

// import ApolloClient, { InMemoryCache } from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient from 'apollo-client';
import { InMemoryCache } from "apollo-cache-inmemory";


import AssociationWrapper from './AssociationWrapper';

import './App.css';


function App() {

  require('dotenv').config();

  const httpLink = createUploadLink({
    uri: process.env.REACT_APP_API_ENDPOINT
  })
  
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    // uri: process.env.REACT_APP_API_ENDPOINT,
    link: httpLink 

  });

  return (
    <>
      <ApolloProvider client={client}>
        <BrowserRouter >
         <AssociationWrapper />
        </BrowserRouter>
      </ApolloProvider>
    </>
  );
}

export default App;

import React, {useContext} from 'react';
import MaterialTable from 'material-table';
import { useQuery } from '@apollo/react-hooks';
import { useHistory} from "react-router-dom";
import NavigationButton from "../../components/NavigationButton";
import moment from 'moment';

import GET_TOPICS from '../../queries/GET_TOPICS';
import GET_DISTRICTS from '../../queries/GET_DISTRICTS';
import GET_STATUS from '../../queries/GET_STATUS';


import AssociationContext from '../../contexts/AssociationContext';

import GET_ACHIEVEMENTS from '../../queries/GET_ACHIEVEMENTS';


const AchievementList = () => {

    const history = useHistory();


    const truncate = (str, n) => {
        if (str == null) {
            return '';
        }
        return (str.length > n) ? str.substr(0, n - 3) + '...' : str;
    };

    const renderLinks = (requestLink, pressReleaseLink, pressFeedbackLink) => {

        const links = [];
        if (requestLink !== null && requestLink !== "") {
            links.push(<div key="1"><a style={{ color: "#2d5029" }} href={requestLink} target="_new">Antrag</a></div>)
        }
        if (pressReleaseLink !== null && pressReleaseLink !== "") {
            links.push(<div key="2"><a style={{ color: "#2d5029" }} href={pressReleaseLink} target="_new">Pressemitteilung</a></div>)
        }
        if (pressFeedbackLink !== null && pressFeedbackLink !== "") {
            links.push(<div key="3"><a style={{ color: "#2d5029" }} href={pressFeedbackLink} target="_new">Presse Feedback</a></div>)
        }
        return <div>{links}</div>;
    }
    const association = useContext(AssociationContext);


    const {data: achievements = { getAchievements: [] }, loading: getAchievementsLoading } = useQuery(GET_ACHIEVEMENTS, {variables: {associationId: association.id }});
    const {data: topicData, loading: getTopicsLoading} = useQuery(GET_TOPICS);
    const {data: statusData, loading: getStatusLoading} = useQuery(GET_STATUS);
    const {data: districtData, loading: getDistrictsLoading} = useQuery(GET_DISTRICTS);



    if (getAchievementsLoading || getTopicsLoading || getDistrictsLoading || getStatusLoading ) {
        return (<div>Loading</div>);
    }

    const topicMap = {};
    topicData.getTopics.forEach(element => {
        topicMap[element.id] = element.name;
    });

    const districtMap = {};
    districtData.getDistricts.forEach(element => {
        districtMap[element.id] = element.name;
    });

    const statusMap = {};
    statusData.getStatus.forEach(element => {
        statusMap[element.id] = element.name;
    });
    // const topicMap = topicData.getTopics.map(element =>  { return {id: element.id, name: element.name}  });



    return (
        <div style={{ maxWidth: '100%' }}>

        <NavigationButton text="Neuen Eintrag erstellen" handleClick={ () => history.push("/admin/achievements/create")} />

            <MaterialTable
                columns={[
                    // { title: 'Id', field: 'id' },
                    { title: 'Name', field: 'name' },
                    { title: 'Datum', field: 'date', render: rowData => rowData.date !== '' && rowData.date !== null  ?  moment(rowData.date).format("DD.MM.YYYY") : '' },

                    // { title: 'Typ des Erfolgs', field: 'achievementType' },
                    { title: 'Typ des Erfolgs', field: 'achievementType', render: rowData => <div title={rowData.achievementType}>{truncate(rowData.achievementType, 50)}</div> },

                    { title: 'Warum ein Erfolg?', field: 'achievementReason', render: rowData => <div title={rowData.achievementReason}>{truncate(rowData.achievementReason, 50)}</div> },
                    // { title: 'Themencluster', render: rowData => rowData.topic != null ? rowData.topic.id : null,                    lookup: topicMap},
                    { title: 'Themencluster', field: 'topicId',                 render: rowData =>{ return rowData.topic !== null ? topicMap[rowData.topic.id] : "" }},
                    { title: 'Themencluster (alt)', render: rowData => rowData.topicText != null ? rowData.topicText : ''},


                    { title: 'Personen', field: 'initiator' },
                    { title: 'Verlinkungen', field: 'requestLink', render: rowData => renderLinks(rowData.requestLink, rowData.pressReleaseLink, rowData.pressFeedbackLink) },
                    { title: 'Kooperation Landesebene', field: 'stateLevelCoorporation', render: rowData =>  { return rowData.stateLevelCooperation ? 'Ja' : 'Nein'}  },
                    { title: 'Adresse', field: 'address' },
                    { title: 'Ortsteil',  field: 'districtId', render: rowData =>  { return rowData.district !== null ? districtMap[rowData.district.id] : "" } },
                    { title: 'Ortsteil (alt)',  render: rowData => <div title={rowData.districtText}>{truncate(rowData.districtText, 50)}</div> },

                    { title: 'Status',  field: 'statusId', render: rowData =>{ return rowData.status !== null ? statusMap[rowData.status.id] : "" } },
                    { title: 'Status (alt)', render: rowData => <div title={rowData.statusText}>{truncate(rowData.statusText, 20)}</div> },

                    { title: 'Aktiv?', field: 'active', render: rowData => rowData.active ? 'Ja' : 'Nein' },
                    { title: 'GPS', render: rowData => rowData.lat != null && rowData.lon != null ? 'vorhanden' : 'fehlt' }

                ]}
                data={achievements.getAchievements}
                title="Grüne Erfolge"
                options={{
                    filtering: true,
                    // paging: false,
                    rowStyle: (rowData, index) => {
                        if (index % 2) {
                            return { backgroundColor: "#f6f6f6" }
                        } else {
                            return { backgroundColor: "#99aa8f" }
                        }
                    }
                }}

                actions={[
                    {
                        icon: 'edit',
                        tooltip: 'Erfolg bearbeiten',
                        onClick: (event, rowData) => {

                            history.push("/admin/achievements/edit/" + rowData.id )
                        }
                    },
                    {
                        icon: 'library_add',
                        tooltip: 'Erfolg kopieren',
                        onClick: (event, rowData) => {

                            history.push("/admin/achievements/copy/" + rowData.id )
                        }
                    },
                    {
                        icon: 'delete',
                        tooltip: 'Erfolg löschen',
                        onClick: (event, rowData) => {
                            history.push("/admin/achievements/delete/" + rowData.id )
                        }
                    }
                ]}
            />
        </div>
    )
}

export default AchievementList;
import React from 'react';
import moment from 'moment';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

const DetailedView = props => {





    const imageTags = [];

    props.data.images.forEach((element, index) => {
        imageTags.push(
            <div key={index}><img key={index} src={element.imageUrl} style={{}} /></div>

        );
    });

    return (
        <div id="detailed_view">

            <div id="detailed_view_header">
                <div id="close_detailed_view" onClick={() => props.hideDetails()} >
                    {/* <img src="/images/close.png"/> */}
                    <div className="close">Schließen</div>
                </div>
            </div>
            <div id="detailed_view_content">

                {/* <h1>Erfolg Nr. {props.data.id}</h1> */}
                {/* <div className="title" >{props.data.name}</div> */}
                <h1>{props.data.name}</h1>

                {/* <div className="detail_label">Datum</div>
            <div className="detail_value">{moment(props.data.date) ? moment(props.data.date).format("DD.MM.YYYY") : "-"}</div> */}

                <h3>Typ des Erfolgs</h3>
                <div className="detail_value">{props.data.achievementType ? props.data.achievementType : "-"}</div>

                <h3>Warum ist es ein Erfolg</h3>
                <div className="detail_value">{props.data.achievementReason ? props.data.achievementReason : "-"}</div>


                {/* <div className="detail_label">Personen</div>
            <div className="detail_value">{props.data.initiator ? props.data.initiator : "-" }</div> */}

                <h3>Verlinkungen</h3>
                <div className="detail_value">
                    {props.data.requestLink && <div><a href={props.data.requestLink} target="_new">Link zum Antrag</a></div>}
                    {props.data.pressReleaseLink && <div><a href={props.data.pressReleaseLink} target="_new">Link zur Pressemitteilung</a></div>}
                    {props.data.pressFeedbackLink && <div><a href={props.data.pressFeedbackLink} target="_new">Link zum Presse-Feedback</a></div>}
                </div>



                <h3>Status</h3>
                <div className="detail_value">{props.data.status && props.data.status.name ? props.data.status.name : "-"}</div>


                {imageTags.length > 0 &&
                    <div>
                        <h3>Bilder</h3>
                        <AwesomeSlider>

                            {imageTags}
                        </AwesomeSlider>
                    </div>
                }
            </div>

        </div >
    )


}

export default DetailedView;
import * as React from 'react';

import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';





import ViewPage from './pages/ViewPage';
import Admin from './features/adminCrud/Admin';

import './App.css';
import Header from './components/Header';

import GET_ASSOCIATION from './queries/GET_ASSOCIATION';
import AssociationContext from './contexts/AssociationContext';




const AssociationWrapper = () => {


  const { data, loading } = useQuery(GET_ASSOCIATION);



  if (loading) {
    return <div>Lade Karte...</div>
  }

  if (data == null) {
    return <div>Die aufrufende Seite ist ungültig</div>
  }



  return (
    <>

      <AssociationContext.Provider value={data.getAssociation}>
        <Switch>
          <Route path="/admin/achievements">
            <Admin />
          </Route>
          <Route path="/" exact={true}>
            <div id="fullpage">
              <Header />
              <ViewPage />
            </div>
          </Route>
          <Route path="/blank">
            <ViewPage />
          </Route>
        </Switch>
      </AssociationContext.Provider>

    </>
  );
}

export default AssociationWrapper;

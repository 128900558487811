import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import AchievementForm from './AchievementForm';
import NavigationButton from '../../components/NavigationButton';
import _ from 'lodash';
import { useHistory, useParams } from "react-router-dom";
import { validateAchievementInput } from './helpers/';

import GET_ACHIEVEMENT from '../../queries/GET_ACHIEVEMENT';

import GET_ACHIEVEMENTS from '../../queries/GET_ACHIEVEMENTS';
import CREATE_ACHIEVEMENT from '../../mutations/CREATE_ACHIEVEMENT';






const AchievementCreate = ({ showFeedbackBar }) => {

  const history = useHistory();
  let { id } = useParams();




  const [createAchievement] = useMutation(CREATE_ACHIEVEMENT);




  const { data, loading: getAchievementLoading } = useQuery(GET_ACHIEVEMENT, { variables: { id: id } });



  if (getAchievementLoading) {
    return (<div>Loading</div>);
  }


  const achievement = data.getAchievement.resource;

  const onSubmit = async data => {

    const achievement = validateAchievementInput(data);
    const result = await createAchievement(
      {
        variables: {
          input: achievement

        },

        update: (cache, { data: { createAchievement } }) => {

          // console.log("Update createAchievement ", createAchievement);

          if (createAchievement.ok) {
            const data: any = cache.readQuery({ query: GET_ACHIEVEMENTS });
            // console.log("Achievements ", data );
            data.getAchievements = [...data.getAchievements, createAchievement.resource];
            // console.log("Data getAchievements ", data.getAchievements );
            cache.writeQuery({ query: GET_ACHIEVEMENTS, data });

            showFeedbackBar("Der neue Eintrag wurde erstellt", "success");
          } else {
            showFeedbackBar("Es sind Fehler aufgetreten. Der Eintrag wurde nicht erstellt. Grund: " + (createAchievement.errors.map(error => error.message)).join(". "), "error");
          }

        },
      }
    );

    console.log("Update result  ", result);


    if (parseInt(_.get(result, 'data.createAchievement.resource.id'))) {
      history.push("/admin/achievements/edit/" + _.get(result, 'data.createAchievement.resource.id'));
    }

    console.log("result", result);



  }









  return (
    <div style={{ maxWidth: '100%' }}>

      <NavigationButton text="Zurück zur Übersicht" handleClick={() => history.push("/admin/achievements")} />

      <AchievementForm achievement={achievement} handleSubmit={onSubmit} buttonText="Eintrag erstellen" />

    </div>
  )
}

export default AchievementCreate;
import gql from 'graphql-tag';

const CREATE_ACHIEVEMENT = gql`
mutation createAchievement( $input:AchievementInput!, $associationId: ID! ) {
  createAchievement(  input: $input, associationId: $associationId ) {
      ok
      errors  {
          field
          code
          message
      }
      resource {
    id
    date
    name
    achievementReason
    achievementType
    initiator
    requestLink
    pressReleaseLink
    pressFeedbackLink
    stateLevelCooperation
    topic {
        id
        name
        identifier
        description
      }
      topicText
      address
      district {
        id
        name
      }
      districtText
      status {
        id
        name
      }

    active
    lat
    lon
    locations
    __typename
  }}
}
`

export default CREATE_ACHIEVEMENT;
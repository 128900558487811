import gql from 'graphql-tag';

const GET_ACHIEVEMENTS = gql `
query getAchievements( $associationId: ID!) { getAchievements( associationId: $associationId ) {
    id
    date
    name
    achievementType
    achievementReason
    initiator
    requestLink
    pressReleaseLink
    pressFeedbackLink
    stateLevelCooperation
    topic {
        id
        name
        identifier
        description
        icon
      }
      topicId
      topicText
      address
      district {
        id
        name
      }
      districtId
      districtText
      status {
        id
        name
      }
      statusId
      statusText
    active
    lat 
    lon 
    locations
    images {
      imageUrl
    }
  }}`;

export default GET_ACHIEVEMENTS;
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

// react-awesome-slider

const UploadMutation = gql`
  mutation uploadFile($achievementId: ID!, $file: Upload!) {
    uploadFile(achievementId: $achievementId, file: $file) {
      path
      id
      filename
      mimetype
      imageUrl
    }
  }
`;





const AchievementImagePreviewer = ({ images }) => {

  if( images === undefined ) {
    return (<div></div>)
  }


  const imageTags = [];
  images.forEach((element, index) => {
    imageTags.push(<div style={{width:'100px', height:'100px',  margin:'10px'  }}>
      <img key={index} src={element.imageUrl} style={{ maxWidth: '100px', maxHeight: '100px'}} />
    </div>
    );
  });

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap:'wrap', justifyContent: 'flex-start' }}>
        {imageTags}
      </div>
    </>
  );
}

export default AchievementImagePreviewer;
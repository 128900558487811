import gql from 'graphql-tag';

const GET_ACHIEVEMENT = gql `
query getAchievement($id: ID!) { getAchievement(id: $id ) {
    ok
    errors {
      code
    }
    resource {
      id
      date
      name
      achievementType
      achievementReason
      initiator
      requestLink
      pressReleaseLink
      pressFeedbackLink
      stateLevelCooperation
      topic {
          id
          name
          identifier
          description
        }
      topicText
      address
      district {
        id
        name
      }
      districtText
      status {
        id
        name
      }
      statusText
      active
      lat 
      lon 
      locations
      __typename
      images {
        imageUrl
      }
  }
  }}`

export default GET_ACHIEVEMENT;
import gql from 'graphql-tag';


const DELETE_ACHIEVEMENT = gql`
mutation deleteAchievement($id:ID! ) {
  deleteAchievement( id:$id ) {
    ok
  }}
`

  export default DELETE_ACHIEVEMENT;
import moment from 'moment';

export const validateAchievementInput = (achievement) => {


    if( achievement.date !== null && achievement.date !== "" ) {
        achievement.date = moment(achievement.date).format("YYYY-MM-DD");
    } else {
        achievement.date = null;
    }

    achievement.stateLevelCooperation = achievement.stateLevelCooperation === 1 ? true : false;
    achievement.topicId = parseInt(achievement.topicId) || null ;
    achievement.statusId = parseInt(achievement.statusId) || null ;
    achievement.districtId = parseInt(achievement.districtId) || null ;


    return achievement;
}
import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import AchievementForm from './AchievementForm';
import NavigationButton from '../../components/NavigationButton';
import GET_ACHIEVEMENT from '../../queries/GET_ACHIEVEMENT';
import UPDATE_ACHIEVEMENT from '../../mutations/UPDATE_ACHIEVEMENT';
import { useHistory, useParams } from "react-router-dom";
import { validateAchievementInput } from './helpers/';







const AchievementEdit = ({ showFeedbackBar }) => {


  const history = useHistory();
  let { id } = useParams();

  const [updateAchievement, { data: updatedAchievement }] = useMutation(UPDATE_ACHIEVEMENT);

  const onSubmit = async (data) => {

    const achievementInput = validateAchievementInput(data);

    const updateResult = await updateAchievement({ variables: { input: achievementInput, id: id } });

    if( updateResult.data.updateAchievement.ok ) {

      showFeedbackBar("Änderungen erfolgreich gespeichert", "success");
    } else {
      showFeedbackBar("Es sind Fehler aufgetreten. Die Änderungen wurden nicht gespeichert", "error");
    }
  }




  const { data, loading: getAchievementLoading } = useQuery(GET_ACHIEVEMENT, { variables: { id: id } });



  if (getAchievementLoading) {
    return (<div>Loading</div>);
  }


  const achievement = updatedAchievement !== undefined && updatedAchievement.updateAchievement !== undefined ? updatedAchievement.updateAchievement.resource : data.getAchievement.resource;



  return (
    <div style={{ maxWidth: '100%' }}>

      <NavigationButton text="Zurück zur Übersicht" handleClick={() => history.push("/admin/achievements")} />
      <NavigationButton text="Neuen Eintrag erstellen" handleClick={() => history.push("/admin/achievements/create")} />


      <AchievementForm achievement={achievement} handleSubmit={onSubmit} buttonText="Speichern" />

    </div>
  )
}

export default AchievementEdit;
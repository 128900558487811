import React, { useState } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { Formik, Field } from 'formik';
import { DatePickerField, FormikRadioGroup } from '../../components/FormFields';
import AchievementDropzone from './AchievementDropzone';
import AchievementImagePreviewer from './AchievementImagePreviewer';






import GET_TOPICS from '../../queries/GET_TOPICS';
import GET_DISTRICTS from '../../queries/GET_DISTRICTS';
import GET_STATUS from '../../queries/GET_STATUS';




const AchievementForm = ({ achievement, handleSubmit, buttonText }) => {

    const { data: topicData, loading: getTopicsLoading } = useQuery(GET_TOPICS);
    const { data: statusData, loading: getStatusLoading } = useQuery(GET_STATUS);
    const { data: districtData, loading: getDistrictsLoading } = useQuery(GET_DISTRICTS);

    const [images, setImages ] = useState(achievement.images);

    const [date] = useState(achievement.date != null ? moment(achievement.date).toDate() : null);


    if (getTopicsLoading || getStatusLoading || getDistrictsLoading) {
        return <div>Loading</div>
    }

    const addImage = image => {
        // console.log("Befroe ", images.length, image );

        // images.push(image);

        // console.log("After ", images.length );

        setImages(images => [...images, image ]);
    }

    let topicOptions = [];
    for (let i = 0; i < topicData.getTopics.length; i++) {
        topicOptions.push(
            (
                <option key={topicData.getTopics[i].id} value={topicData.getTopics[i].id}>{topicData.getTopics[i].name}</option>
            ));
    }

    let districtOptions = [];
    for (let i = 0; i < districtData.getDistricts.length; i++) {
        districtOptions.push(
            (
                <option key={districtData.getDistricts[i].id} value={districtData.getDistricts[i].id}>{districtData.getDistricts[i].name}</option>
            ));
    }

    let statusOptions = [];
    for (let i = 0; i < statusData.getStatus.length; i++) {
        statusOptions.push(
            (
                <option key={statusData.getStatus[i].id} value={statusData.getStatus[i].id}>{statusData.getStatus[i].name}</option>
            ));
    }

    const stateLevelCooperationOptions = {
        1: "JA",
        0: "NEIN"

        
    }

    console.log("Form ", achievement.id );

    return (

        <Formik
            initialValues={
                {
                    date: achievement.date,
                    name: achievement.name,
                    achievementReason: achievement.achievementReason,
                    achievementType: achievement.achievementType,
                    initiator: achievement.initiator,
                    requestLink: achievement.requestLink,
                    pressReleaseLink: achievement.pressReleaseLink,
                    pressFeedbackLink: achievement.pressFeedbackLink == null ? "" : achievement.pressFeedbackLink,
                    stateLevelCooperation: achievement.stateLevelCooperation,
                    topicId: _.get(achievement, 'topic.id'),
                    topicText: achievement.topicText,
                    address: achievement.address,
                    districtId: _.get(achievement, 'district.id'),
                    districtText: achievement.districtText,
                    statusId: _.get(achievement, 'status.id'),
                    statusText: achievement.statusText,
                    active: achievement.active,
                    locations: achievement.locations
                }
            }
            validate={values => {

            }}

            onSubmit={(values) => {


                handleSubmit(values, date !== null ? moment(date).format("DD.MM.YYYY") : null);

            }}
        >

            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
            }) => (

                <form onSubmit={handleSubmit}>

                    <div className="column">

                        <label>Titel</label>
                        <input type="text" name="name" value={values.name} onChange={handleChange} onBlur={handleBlur} />
                        {errors.name && <div className="input_error">Fehler beim Namen</div>}

                        <label>Datum des Erfolgs</label>


                        <DatePickerField
                            name="date"
                            value={values.date}
                            onChange={setFieldValue}
                        />






                        {errors.date && <div className="input_error">Das Datum muss so angegeben werden: TT.MM.JJJJ</div>}

                        <label>Typ des Erfolgs</label>
                        <input type="text" name="achievementType" value={values.achievementType} onChange={handleChange} onBlur={handleBlur} />




                        <label>Warum ist es ein Erfolg</label>
                        <textarea name="achievementReason" value={values.achievementReason} onChange={handleChange} onBlur={handleBlur}  ></textarea>





                        <label>Personen</label>
                        <input type="text" name="initiator" value={values.initiator} onChange={handleChange} onBlur={handleBlur} />


                        <label>Link zum Antrag</label>
                        <input type="text" name="requestLink" value={values.requestLink} onChange={handleChange} onBlur={handleBlur} />


                        <label>Link zur PM</label>
                        <input type="text" name="pressReleaseLink" value={values.pressReleaseLink} onChange={handleChange} onBlur={handleBlur} />


                        <label>Link zum Presse-Feedback</label>
                        <input type="text" name="pressFeedbackLink" value={values.pressFeedbackLink} onChange={handleChange} onBlur={handleBlur} />

                        <label>Bilder</label>
                        <AchievementDropzone achievementId={achievement.id} addImage={addImage} />
                        <AchievementImagePreviewer images={images} />

                    </div>
                    <div className="column">

                        <label>Themencluster</label>


                        <Field name="topicId" component="select" value={values.topicId} onChange={handleChange} onBlur={handleBlur}>
                            <option value="">Bitte wählen</option>
                            {topicOptions}
                        </Field>

                        <label>Kooperation mit Landesebene</label>




                        <Field
                            name="stateLevelCooperation"
                            options={stateLevelCooperationOptions}
                            component={FormikRadioGroup}
                            value={values.stateLevelCooperation === "1" ? "1" : "0"}
                        />






                        <label>Adresse</label>

                        <input type="text" name="address" value={values.address} onChange={handleChange} onBlur={handleBlur} />


                        <label>Ortsteil</label>


                        <Field name="districtId" component="select" value={values.districtId} onChange={handleChange} onBlur={handleBlur}>
                            <option value="">Bitte wählen</option>
                            {districtOptions}
                        </Field>


                        <label>Status</label>


                        <Field name="statusId" component="select" value={values.statusId} onChange={handleChange} onBlur={handleBlur}>
                            <option value="">Bitte wählen</option>
                            {statusOptions}
                        </Field>


                        <label>Status Freitext</label>

                        <input type="text" name="statusText" defaultValue={achievement.statusText} />

                        <label>Soll es in der Karte angezeigt werden?</label>
                        <fieldset>
                            <label>
                                <Field name="active" type="checkbox" onChange={handleChange} onBlur={handleBlur} />
                Ja
                </label>
                        </fieldset>

                        <label>GPS</label>
                        <input type="text" defaultValue={(achievement.lat != null ? achievement.lat : '-') + ',' + (achievement.lon != null ? achievement.lon : '-')} readOnly={true} />

                        <label>GPS Koordinaten bei mehreren Standorten</label>
                        <textarea name="locations" value={values.locations} onChange={handleChange} onBlur={handleBlur}  ></textarea>


                        <input type="submit" value={buttonText} />
                    </div>
                </form>

            )}

        </Formik>
    );
}

export default AchievementForm;
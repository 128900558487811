import React, { useState, useCallback } from 'react';
import TsMap from './TsMap';
import LeafletMap from './LeafletMap';
import DetailedView from './DetailedView';


const MapView = ({ data, selectedIds }) => {

    const [showDetailedView, setShowDetailedView] = useState(false);


    const objectMap = {};
    data.forEach((item) => {
        objectMap[item.id] = item;
    });

    const hideDetails = () => {
        setShowDetailedView(false);
    }

    const showDetails = useCallback(id => {
        setDetails(objectMap[id]);
        setShowDetailedView(true);
    }, [objectMap]);

    const [details, setDetails] = useState({});

    return (
        <div className="mapview">

            <div style={{width: '100%'}}>
                <div id="disclaimer">{data.length} Erfolge seit 2016</div>
                <LeafletMap data={data} selectedIds={selectedIds} showDetails={showDetails} />
            

                </div>
            {showDetailedView && <DetailedView data={details} hideDetails={hideDetails}  />}
            {/* {showInitialHint && <InitialView data={details} hideDetails={hideDetails}  />} */}
        </div>
    )
}



export default MapView;
import gql from 'graphql-tag';

const GET_ASSOCIATION = gql`
query getAssociation { getAssociation {
    id
    identifier
    name
    centerLat
    centerLon
    zoomTop
    zoomBuilding
}}
`

export default GET_ASSOCIATION;
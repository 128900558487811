import React, {useContext} from 'react';
import {  useMutation } from '@apollo/react-hooks';
import AchievementForm from './AchievementForm';
import NavigationButton from '../../components/NavigationButton';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import { validateAchievementInput } from './helpers/';


import GET_ACHIEVEMENTS from '../../queries/GET_ACHIEVEMENTS';
import CREATE_ACHIEVEMENT from '../../mutations/CREATE_ACHIEVEMENT';
import AssociationContext from '../../contexts/AssociationContext';





const AchievementCreate = ({showFeedbackBar}) => {

  const history = useHistory();

  const [createAchievement] = useMutation(CREATE_ACHIEVEMENT);
  const association = useContext(AssociationContext);


  const onSubmit = async data => {

    const achievement = validateAchievementInput(data);



    const result = await createAchievement(
      {
        variables: {
          input: achievement,
          associationId: association.id
          
        },

        update: (cache, { data: { createAchievement } }) => {


          if (createAchievement.ok) {
            const data: any = cache.readQuery({ query: GET_ACHIEVEMENTS, variables:{associationId: association.id }  });
            data.getAchievements = [...data.getAchievements, createAchievement.resource];
            cache.writeQuery({ query: GET_ACHIEVEMENTS,  variables:{associationId: association.id }, data });

            showFeedbackBar("Der neue Eintrag wurde erstellt", "success");
          } else {
            showFeedbackBar("Es sind Fehler aufgetreten. Der Eintrag wurde nicht erstellt. Grund: " + (createAchievement.errors.map( error => error.message)).join(". "), "error");
          }

        },
      }
    );


    if (parseInt(_.get(result, 'data.createAchievement.resource.id'))) {
      history.push("/admin/achievements/edit/" + _.get(result, 'data.createAchievement.resource.id'));
    }

  }









  return (
    <div style={{ maxWidth: '100%' }}>

      <NavigationButton text="Zurück zur Übersicht" handleClick={() => history.push("/admin/achievements")} />

      <AchievementForm achievement={{}} handleSubmit={onSubmit}  buttonText="Eintrag erstellen" />

    </div>
  )
}

export default AchievementCreate;
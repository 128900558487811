import React, {useContext} from 'react';
import {  useMutation } from '@apollo/react-hooks';
import NavigationButton from '../../components/NavigationButton';
import { useHistory, useParams } from "react-router-dom";

import DELETE_ACHIEVEMENT from "../../mutations/DELETE_ACHIEVEMENT";
import GET_ACHIEVEMENTS from '../../queries/GET_ACHIEVEMENTS';

import AssociationContext from '../../contexts/AssociationContext';





const AchievementDelete = ({ showFeedbackBar  }) => {

  const history = useHistory();

  const association = useContext(AssociationContext);


  let { id } = useParams();

  const [deleteAchievement, {loading}] = useMutation(DELETE_ACHIEVEMENT);

  const onSubmit = () => {

    console.log("delete id", id );
    deleteAchievement({
      variables: { id: parseInt(id) },
      update: (cache, { data: { deleteAchievement } }) => {


        console.log('result ', deleteAchievement);
        if (deleteAchievement.ok) {


          const data: any = cache.readQuery({ query: GET_ACHIEVEMENTS,  variables: {associationId: association.id } });
          data.getAchievements = data.getAchievements.filter(({ id: achievementId }) => achievementId !== id);


          cache.writeQuery({ query: GET_ACHIEVEMENTS, data: { getAchievements: data.getAchievements },  variables: {associationId: association.id } });

          showFeedbackBar("Der Eintrag wurde gelöscht", "success");
          history.push("/admin/achievements");
        } else {
          showFeedbackBar("Es ist ein Fehler aufgetreten. Der Eintrag wurde nicht gelöscht", "error");
        }







      }
    });

  }



  return (
    <div style={{ maxWidth: '100%' }}>

      <div className="delete_container">

        <div className="delete_text">
          Bist du Sicher, dass Du diesen Eintrag löschen möchtest? Du kannst ihn über die Bearbeiten-Funktion für die Kartendarstellung auch deaktivieren.
              </div>



        <div className="delete_options">

          <NavigationButton text="Nein - Zurück zur Übersicht" handleClick={() => history.push("/admin/achievements")} />

          <NavigationButton text="Nein - Eintrag bearbeiten" handleClick={() => history.push("/admin/achievements/edit/" + id)} />


          <input type="submit" className="delete" value="Ja - Eintrag endgültig löschen" onClick={onSubmit} />
        </div>


      </div>



    </div >
  )
}

export default AchievementDelete;
import React, {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

// react-awesome-slider

const UploadMutation = gql`
  mutation uploadFile($achievementId: ID!, $file: Upload!) {
    uploadFile(achievementId: $achievementId, file: $file) {
      path
      id
      filename
      mimetype
      imageUrl
    }
  }
`;





const AchievementDropzone = ( {achievementId, addImage }) => {
    const [uploadFile] = useMutation(UploadMutation,{ onCompleted: ({uploadFile} ) => { addImage(uploadFile)}});

    const onDrop = useCallback((acceptedFiles) => {
        // do something here
        console.log(acceptedFiles);

        const file = acceptedFiles[0];
        console.log("AchievementId ", achievementId );

      // use the uploadFile variable created earlier
      uploadFile({

        // use the variables option so that you can pass in the file we got above
        variables: { achievementId, file },
       
      });


      }, []);
      const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
      });
      return (
        <>
          <div {...getRootProps()} className={`dropzone ${isDragActive && "isActive"}`}>
            <input {...getInputProps()} />
            {isDragActive ? <p>Drop the files here ...</p> : <p>Drag 'n' drop some files here, or click to select files</p>}
          </div>
        </>
      );
}

export default AchievementDropzone;
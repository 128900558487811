import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import InputMask from "react-input-mask";
import DatePicker from "react-datepicker";

const DatePickerField = ({ name, value, onChange }) => {
    return (
        <DatePicker
            selected={(value && new Date(value)) || null}
            dateFormat="dd.MM.yyyy"
            onChange={val => {
                onChange(name, val);
            }}
        />
    );
};





const MaskedInputDate = (props) => {
  const { inputRef, ...other } = props;

  return (
    <InputMask
      mask="99.99.9999"
      {...other}
      onChange={props.onChange} value={props.value}
    />
  );
}


const renderOptions = (options, fieldName) => {

  const renderedOptions = [];
  Object.keys(options).forEach(value => {
    renderedOptions.push(<FormControlLabel
      key={value}
      value={value}
      control={<Radio color="primary" data-cy={"option-" + fieldName + "-" + value }/>}
      label={options[value]}
    />);
  });

  return renderedOptions;

};

const FormikRadioGroup = ({
  field,
  form: { touched, errors },
  name,
  options,
  children,
  ...props
}) => {
  const fieldName = name || field.name;


  return (
    <React.Fragment>
      <RadioGroup {...field} {...props} name={fieldName}>

        {options ? renderOptions(options,fieldName) : children}
      </RadioGroup>

  
    </React.Fragment>
  );
};


export {
  MaskedInputDate,
  FormikRadioGroup,
  DatePickerField
}


import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';

import AchievementCreate from './AchievementCreate';
import AchievementEdit from './AchievementEdit';
import AchievementCopy from './AchievementCopy';
import AchievementDelete from './AchievementDelete';
import AchievementList from './AchievementList';
import AdminFeedbackBar from './AdminFeedbackBar';




const Admin = () => {


    const [feedbackBarVisible, setFeedbackBarVisible] = useState(false);
    const [feedbackBarType, setFeedbackBarType] = useState("success");
    const [feedbackBarMessage, setFeedbackBarMessage] = useState("");


    const showFeedbackBar = (message, type) => {
        setFeedbackBarType(type);
        setFeedbackBarMessage(message);
        setFeedbackBarVisible(true);

        setTimeout(() => {
            setFeedbackBarVisible(false);
        }, 3000);
    }





    return (
        <div id="admin_page">
            <Switch>
                <Route path="/admin/achievements" exact={true} component={AchievementList} />
                <Route path="/admin/achievements/edit/:id" >
                    <AchievementEdit showFeedbackBar={showFeedbackBar} />
                </Route>
                <Route path="/admin/achievements/copy/:id" >
                    <AchievementCopy showFeedbackBar={showFeedbackBar} />
                </Route>
                <Route path="/admin/achievements/delete/:id">
                    <AchievementDelete  showFeedbackBar={showFeedbackBar}/>
                </Route>
                <Route path="/admin/achievements/create">
                    <AchievementCreate  showFeedbackBar={showFeedbackBar}/>
                    </Route> 

            </Switch>

            <AdminFeedbackBar feedbackBarVisible={feedbackBarVisible} feedbackBarMessage={feedbackBarMessage} feedbackBarType={feedbackBarType} />
        </div>
    )
}

export default Admin;
import React, { useState, useCallback , useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

import OverviewTableSimple from '../features/OverviewTableSimple';
import MapView from '../features/MapView';
import DistrictTopicMatrix from '../features/DistrictTopicMatrix';



import { useQuery } from '@apollo/react-hooks';



import GET_ACHIEVEMENTS from './../queries/GET_ACHIEVEMENTS';
import AssociationContext from '../contexts/AssociationContext';

// const GET_ACHIEVEMENTS = gql`
// query getAchievements{ 
//     getAchievements {
//     id
//     date
//     name
//     achievementType
//     achievementReason
//     initiator
//     requestLink
//     pressReleaseLink
//     pressFeedbackLink
//     stateLevelCooperation
//     topic {
//         id
//         name
//         identifier
//         description
//         icon
//       }
//       topicId
//       topicText
//       address
//       district {
//         id
//         name
//       }
//       districtId
//       districtText
//       status {
//         id
//         name
//       }
//       statusId
//       statusText
//     active
//     lat 
//     lon 
//     locations
//     images {
//       imageUrl
//     }
//   }

//   getDistricts {
//     id
//     name
// }

// getTopics {
//   id
//   name
//   }

// }
// `

function TabPanel(props) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (

        <div className="MuiBox-root-31">
          <div className="MuiTypography-body1" style={{ margin: "40px" }}>
            <Box>{children}</Box>
          </div>
        </div>

      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
  },
}));

const truncate = (str, n) => {

  if (str == null) {
    return '';
  }
  return (str.length > n) ? str.substr(0, n - 3) + '...' : str;
};


const ViewPage = () => {

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [selectedIds, setSelectedIds] = useState([]);
  const alwaysSelectedIds  = [];

  const association = useContext(AssociationContext);
  

  const { data: achievements = { getAchievements: [] }, loading: getAchievementsLoading } = useQuery(GET_ACHIEVEMENTS, {variables: {associationId: association.id}});

  const objectMap = {};
  achievements.getAchievements.forEach((item) => {

    item.achievementTypeTruncated = truncate(item.achievementType, 50);
    objectMap[item.id] = item;
    alwaysSelectedIds.push(item.id);
  });


  const handleSelectionChange = useCallback((selectedIds) => {
    setSelectedIds([...selectedIds]);
    setValue(1);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (getAchievementsLoading) {
    return (
      <div id="loading">
        <div>Lade Erfolge </div>
      </div>
    )

  }


  return (

    <div className={classes.root}>
      {/* <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          
          <Tab label="Karte" {...a11yProps(0)} />
          <Tab label="Erfolge" {...a11yProps(1)} />
          <Tab label="Themencluster / Ortsteile" {...a11yProps(2)} />
        </Tabs>
      </AppBar> */}
      {/* <TabPanel value={value} index={0}> */}
        <MapView data={achievements.getAchievements} selectedIds={alwaysSelectedIds} />
      {/* </TabPanel >
      <TabPanel value={value} index={1}>
        <OverviewTableSimple data={achievements.getAchievements} handleSelectionChange={handleSelectionChange} />
      </TabPanel>
    
      <TabPanel value={value} index={2}>
        <DistrictTopicMatrix data={achievements} selectedIds={selectedIds} />
      </TabPanel> */}
    </div>









  )
}

export default React.memo(ViewPage);

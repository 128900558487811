import React, {useContext} from 'react';
import { Link, useLocation } from 'react-router-dom';
import AssociationContext from '../contexts/AssociationContext';



const Header = () => {

    let location = useLocation();
    const pathname = location.pathname;

    const association = useContext(AssociationContext);


    return (
        <div id="header">
           
           <div className="container">
                 {/* <div className="logo_title">
                    

                    <div className="title">Karte mit grünen Erfolgen</div>
                </div>  */}
                <img id="header_logo" src='/images/sonnenblume.png' alt="Logo Bündnis 90/Die Grünen" />

                <div className="hgroup">
                    <h1 id="site-title"><span><a href="https://gruene-ts.de/" title="Bündnis 90/Die Grünen" rel="home">Karte der grünen Erfolge</a></span></h1>
                    <h2 id="site-description">{association.name}</h2>
                </div>

                 {/* <div className="links">
                    {pathname.match("achievements") && <Link to="/">Ansicht</Link>}
                    {!pathname.match("achievements") && <Link to="/admin/achievements">Daten bearbeiten</Link>}
                </div>  */}

            </div>
        </div>

    )
}


export default Header;
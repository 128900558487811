import React from 'react';


const AdminFeedbackBar = ({ feedbackBarVisible, feedbackBarType, feedbackBarMessage }) => {

    if (!feedbackBarVisible) {
        return <></>;
    }

    return (


        <div id="admin_feedback_bar" >
            <div id="admin_feedback_bar_container" className={feedbackBarType}>


                <div className="admin_feedback_text">
                    {feedbackBarMessage}
                </div>
            </div>
        </div>
    )
}

export default AdminFeedbackBar;
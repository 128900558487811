import gql from 'graphql-tag';

const GET_TOPICS = gql`
query getTopics { getTopics {
    id
    name
}}
`

export default GET_TOPICS;

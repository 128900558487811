import React, { useRef, useEffect, useState, memo, useContext } from 'react';
import AssociationContext from '../contexts/AssociationContext';
import * as L from "leaflet";

// mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;




const LeafletMap = ({ data, selectedIds, showDetails }) => {

  const association = useContext(AssociationContext);
  const achievements = data;

  let currentCenter = [association.centerLon, association.centerLat];
  const mapContainerRef = useRef(null);
  const [view, setView] = useState('top');
  const [lng, setLng] = useState(association.centerLon);
  const [lat, setLat] = useState(association.centerLat);
  let currentZoom = view === 'top' ? [association.zoomTop] : [association.zoomBuilding];
  const [zoom, setZoom] = useState(currentZoom);

  const cdnUrl = process.env.NODE_ENV == 'development' ? 'http://map-cdn' : 'https://karte.gruene-ts.de'


  const handleMarkerClick = (string) => {
    showDetails(string);
  };


  useEffect(() => {
    var map = L.map('leafletmap').setView([lat, lng], zoom);


    // L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    L.tileLayer('https://tile.openstreetmap.de/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);


    console.log("Association ", association.identifier);
    const districtBorders = require('../data/leaflet/' + association.identifier);

    const districtLines = districtBorders.map(e => e.reverse());
    var polyline = L.polyline(districtBorders, { color: 'green' }).addTo(map);
    map.fitBounds(polyline.getBounds());



    const defaultSize = '30px';
    const increasedSize = '50px';

    const defaultZIndex = 50;
    const hoveredZIndex = 80;

    const existingCoordinates = [];

    achievements.forEach((item, index) => {

      // console.log(item);

      // var el = document.createElement('img');
      // el.id = item.id;
      // el.src = cdnUrl + "/images/circle/circle_" + item.topic.icon + ".svg";
      // el.style.width = defaultSize;
      // el.style.height = defaultSize;
      // el.style.borderRadius = '50%';
      // el.style.zIndex = defaultZIndex;
      // //el.style.backgroundColor = 'black';

      // // Create a default Marker and add it to the map.
      // var marker = new mapboxgl.Marker(el)
      //   .setLngLat([item.lon, item.lat])
      // console.log("icon ", item.lat, item.lon );

      if (item.topic != null && item.lat != null && item.lon != null) {




        var myIcon = L.icon({
          iconUrl: cdnUrl + "/images/circle/circle_" + item.topic.icon + ".svg",
          iconSize: [30, 30],
          iconAnchor: [0, 0],
          // popupAnchor: [-3, -76],
          // shadowUrl: 'my-icon-shadow.png',
          // shadowSize: [68, 95],
          // shadowAnchor: [22, 94]
        });

        // if( item.id == 185 || item.id== 148) {
          
        // }

        // if(item.id == 148 ) {
        //   console.log("Health", item );
        // }

        const latRounded = item.lat.toFixed(3);
        const lonRounded = item.lon.toFixed(3);
        const coordRounded = latRounded + lonRounded;

        let latAdjusted = 0;
        let lonAdjusted = 0;

        if( existingCoordinates.indexOf(coordRounded) > -1  ) {
          const latSign = Math.random() > 0.5 ? 1 : -1;
          const lonSign = Math.random() > 0.5 ? 1 : -1;

          latAdjusted = item.lat + ( latSign *   0.0005 * Math.random())
          lonAdjusted = item.lon + ( lonSign *   0.0005 * Math.random())
        } else {
          latAdjusted = item.lat;
          lonAdjusted = item.lon;
          existingCoordinates.push(coordRounded);
        }

 




        

        const marker = L.marker([latAdjusted, lonAdjusted], { icon: myIcon });
        marker.addTo(map);


        marker.getElement().addEventListener('mouseenter', (event) => {
          event.target.style.width = increasedSize;
          event.target.style.height = increasedSize;
          event.target.style.zIndex = hoveredZIndex;
          // console.log("mouseenter 1", event.target );
          // console.log("mouseenter 2", event.target.options.icon  );
        });

        marker.getElement().addEventListener('mouseleave', (event) => {
          event.target.style.width = defaultSize;
          event.target.style.height = defaultSize;
          event.target.style.zIndex = defaultZIndex;
        });


        marker.getElement().addEventListener('click', () => {
          handleMarkerClick(item.id);
        });


        //         var icon = centerMarker.options.icon;
        // icon.options.iconSize = [newwidth, newheight];
        // centerMarker.setIcon(icon);

      }
    });





    // map.on('load', function () {
    //   const districtBorders = require('../data/' + association.identifier);


    //   map.addSource('district', {
    //     'type': 'geojson',
    //     'data': districtBorders
    //   });

    //   map.addLayer({
    //     'id': 'district',
    //     'type': 'line',
    //     'source': 'district',
    //     'layout': {},
    //     'paint': {
    //       'line-color': '#3a9435',
    //       'line-width': 2
    //     }
    //   });



    // L.marker([51.5, -0.09]).addTo(map)
    //     .bindPopup('A pretty CSS3 popup.<br> Easily customizable.')
    //     .openPopup();

  }, []);



  return (
    <div>
      <div className='sidebarStyle'>
        <div>
        </div>
      </div>
      <div id="leafletmap" className='map-container' ref={mapContainerRef} />
    </div>
  );
}

export default LeafletMap;
